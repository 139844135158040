import React from 'react'
import { connect } from 'react-redux'

import Layout from '../../../../components/Layout'
import BrandTabs from '../../../../components/marketplace/brand/BrandTabs'

class Sentiment extends React.Component {
  render() {
    const { user } = this.props
    const dellUser = user && user.email && user.email.includes('@dell.com')
    return (
      <Layout noFooter active="brand" requireAuth>
        {!dellUser && (
          <>
            <BrandTabs active="sentiment" />
            <iframe
              title="brand_infogram"
              src={this.props.brandTrackerLink}
              className="full-page-iframe"
            />
          </>
        )}
        {dellUser && (
          <iframe
            title="brand_infogram"
            src="https://infogram.com/dell-brand-pageintelligence-insights-live-1ho16vol51kd74n?live"
            className="full-page-iframe"
          />
        )}
      </Layout>
    )
  }
}

export default connect(
  state => ({
    brandTrackerLink: state.brandReports.sentimentInfogram,
    user: state.user.user,
  }),
  {}
)(Sentiment)
